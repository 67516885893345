import React, { useLayoutEffect, useState } from "react";
import { Routes, Route, useLocation, Router } from "react-router-dom";

import Layout from "../layout/Index";
import Blog from "../Components/Blog.jsx";
import Blogdetails from "../Components/Blogdetails";
import Podcast from "../Components/Podcast";
import Category from "../Components/Category";
import Bloghead from "../Components/Bloghead";
import Index from '../layout/Index';

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const BlogPage = () => (
    <>
      <Blog />
      {/* <Paginationbtm /> */}
    </>
  );
  const [selectedCategory, setSelectedCategory] = useState("");

  return (
    <>
      <div className="Pages" style={{ backgroundColor: process.env.REACT_APP_BACKGROUND_COLOR }}>
        <Bloghead />
        <Routes>
          <Route path={`${process.env.PUBLIC_URL}`} element={<Index />} >
            <Route path="/" element={<BlogPage />} />
            <Route path="/Blogdetails/:id" element={<Blogdetails />} />
            <Route path="/category/:name" element={<Category />} />
            <Route path="/podcast" element={<Podcast />} />

          </Route>
        </Routes>

      </div>
    </>
  );
};
export default Pages;
