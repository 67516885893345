import React from "react";
import "./Blog.css";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default function Blogitem(props) {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().substr(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${day}-${month}-${year}`;
  };
  return (
    <React.Fragment>
      <div className="row featurette ">
        {props.position === "left" ? (
          <div className="col-lg-5 col-md-6 " >
            {props.is_video ? (
              <video
                controls
                style={{ objectFit: 'cover' }}
              >
                <source src={props.video} type="video/mp4" />
              </video>
            ) : (
              <img
                src={props.Img}
                className=" img-fluid mx-auto"
                style={{ objectFit: 'contain' }}
              />
            )}
          </div>
        ) : (
          ""
        )}
        <div className="col-lg-7 col-md-6 ">
          <Link to={`/Blogdetails/${props.id}`} className="text-decoration-none">
            <div>
              <h4
                className={` fw-bold py-2 ${process.env.REACT_APP_TEXT_COLOR} lh-1`}
              >
                {props.title}
              </h4>
            </div>
          </Link>
          <div className="container">
            <div className="row d-flex blogitemrow ">
              <div className="col-12 d-flex justify-content-between p-0">
                <div className="col-3 col-md-3 d-flex">
                  <i
                    className={`bi bi-person-fill pe-2 ${process.env.REACT_APP_TEXT_COLOR}`}
                  ></i>
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-start`}>
                    {props.author}
                  </p>
                </div>
                <div className="col-3 col-md-3 d-flex">
                  <i
                    className={`bi bi-calendar-date pe-2 ${process.env.REACT_APP_TEXT_COLOR}`}
                  ></i>
                  <p className={`${process.env.REACT_APP_TEXT_COLOR} text-start`}>
                    {formatDate(props.created_at)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p className={`lead description ${process.env.REACT_APP_TEXT_COLOR}`}>
            {props.description}
            <Link
              to={`/Blogdetails/${props.id}`}
              className="me-auto"
              style={{ color: process.env.REACT_APP_READ_MORE_TEXT_COLOR }}
            >
              Read More
            </Link>
          </p>
        </div>
        {props.position === "right" ? (
          <div className="col-lg-5 col-md-6 col-12" style={{ height: '150px' }}>
            {props.is_video ? (
              <video
                controls
                style={{
                  height: '-webkit-fill-available',
                  objectFit: 'cover'
                }}
              >
                <source src={props.video} type="video/mp4" />
              </video>
            ) : (
              <img
                src={props.Img}
                className="featurette-image img-fluid mx-auto"
                style={{ objectFit: 'contain' }}
              />
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
}
